import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            heading: {
                paddingBottom: theme.spacing(1),
            },
            description: {
                paddingBottom: theme.spacing(3),
            },
            divider: {
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
            },
        }),
    {
        name: 'GlobalSettings-',
    },
);
