import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Switch } from '@bb-ui/react-library/dist/components/Switch';
import { FormControlLabel } from '@bb-ui/react-library/dist/components/FormControlLabel';
import { Information } from '@bb-ui/icons/dist/small/Information';
import { Link } from '@bb-ui/react-library';
import { useStyles } from './AIOptIn.styles';
import { useTenantSettingsContext } from '../../../../contexts/TenantSettingsContext';
import { useHelpLinks } from '../../../../hooks/useHelpLinks';

export const AIOptIn: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const getHelpLink = useHelpLinks();

    const {
        loading,
        data: { aiOptIn = false } = {},
        patchSettings,
    } = useTenantSettingsContext();

    return (
        <>
            <Typography
                variant="h3"
                className={classes.title}
                data-testid="ai_opt_in-title"
            >
                {t('settings.globalSettings.aiOptIn.title')}
            </Typography>
            <FormControlLabel
                label={t('settings.globalSettings.aiOptIn.switch')}
                htmlFor="ai_opt_in-checkbox"
                control={
                    <Switch
                        value="ai_opt_in-checkbox"
                        id="ai_opt_in-checkbox"
                        data-testid="ai_opt_in-checkbox"
                        disabled={loading || !patchSettings}
                        checked={aiOptIn}
                        onChange={({ target: { checked } }) =>
                            patchSettings && patchSettings({ aiOptIn: checked })
                        }
                    />
                }
            />
            <div className={classes.infoSection}>
                <Information />
                <Typography
                    component="p"
                    className={classes.infoText}
                    data-testid="ai_opt_in-description"
                >
                    <Trans i18nKey="settings.globalSettings.aiOptIn.description">
                        Generative AI features in Illuminate Reporting,
                        including Data Q&A use models provided by AWS Bedrock,
                        through AWS QuickSight.{' '}
                        <span style={{ fontWeight: 'bold' }}>NOTE:</span> These
                        features may produce undesired or inaccurate output. It
                        is your responsibility to review output and correct or
                        modify it as may be necessary or appropriate. Data used
                        for these features may be transmitted to AWS servers
                        located outside of your hosting region. No client data
                        is stored or used to train, retrain or improve AWS's
                        generative AI models.
                        <Link
                            href={getHelpLink('aiTransparencyNote')}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Find more information on risks and limitations of
                            the feature in the standard end user documentation.
                        </Link>
                        By enabling this feature, you confirm you accept the
                        <Link
                            href={getHelpLink('generativeAiTerms')}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            additional terms
                        </Link>
                        on behalf of your institution and that you have read and
                        understood the information about risk and limitations
                        within and/or referenced in the standard end user
                        documentation referenced above.
                    </Trans>
                </Typography>
            </div>
        </>
    );
};
