import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { useStyles } from './GlobalSettings.styles';

import { InstitutionalEmail } from './InstitutionalEmail/InstitutionalEmail';
import { SessionTimeoutDuration } from './SessionTimeout/SessionTimeoutDuration';
import { AIOptIn } from './AIOptIn/AIOptIn';

export const GlobalSettings: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Typography
                variant="h2"
                className={classes.heading}
                data-testid="heading"
            >
                {t('settings.globalSettings.heading')}
            </Typography>
            <Typography
                component="p"
                className={classes.description}
                data-testid="description"
            >
                {t('settings.globalSettings.description')}
            </Typography>
            <AIOptIn />
            {/* <SessionTimeoutDuration />
            <hr className={classes.divider} />
            <InstitutionalEmail /> */}
        </>
    );
};
